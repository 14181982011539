.App {
  text-align: center;
}

*, :after, :after {
  box-sizing: border-box !important;
} 

.introjs-tooltip {
  background-color: transparent !important;
  right: 0px;
  min-width: 282px !important;
  color: #ffffff;
}

.introjs-tooltipReferenceLayer * {
  font-family: 'Josefin Sans', sans-serif !important;
}

.introjs-tooltipReferenceLayer{
  top: 0 !important;
  height: 100% !important;
}

.onboarding-title {
  padding-top: 15px;
  font-size: 1.2pc;
  font-weight: bold;
}

.introjs-tooltip-header,
.introjs-skipbutton,
.introjs-arrow {
  display: none !important;
}

.tooltip0 p {
  font-size: 13px;
}

.tooltip0 {
  background-color: #ffffff !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 350px !important;
  left: 20% !important;
}

.tooltip1 .introjs-tooltipbuttons,
.tooltip2 .introjs-tooltipbuttons,
.tooltip3 .introjs-tooltipbuttons {
  border: none;
}

.tooltip1 {
  text-align: center;
  position: absolute;
  left: 50% !important;
  transform: translate(-50%, -50%);
  top: 25% !important;
  margin: 0 !important;
}

.tooltip1 .introjs-tooltipbuttons {
  box-sizing: border-box;
}

.tooltip1 .introjs-prevbutton {
  position: absolute;
  left: -15px;
}

.tooltip1 .introjs-nextbutton {
  position: absolute;
  right: 0;
}

@media (max-width: 580px) {
  .tooltip1 .introjs-nextbutton {
    position: absolute;
    right: -15px;
  }
}

.tooltip2 p {
  position: absolute;
  top: 100px;
}

.tooltip2 {
  width: 100% !important;
  max-width: 100% !important;
  left: unset !important;
  box-sizing: border-box !important;
  top: 20% !important;
}

.tooltip2 .introjs-tooltiptext {
  box-sizing: border-box;
  position: relative;
  min-height: 200px;
  max-height: 250px;
}

.tooltip3 {
  left: auto !important;
  top: 7% !important;
}

.tooltip3 .introjs-tooltiptext {
  box-sizing: border-box;
  position: relative;
  min-height: 180px;
  max-height: 250px;
}

.tooltip3 .step2-arrow-right {
  position: absolute;
  right: 0px;
  top: 10px;
}

.tooltip3 p {
  position: absolute;
  top: 100px;
}

.step2-arrows {
  text-align: center;
}

.clear {
  clear: both;
}

.intro-map {
  background-position: center;
  height: 100px !important;
  background-repeat: no-repeat;
}

.step1 {
  border: 2px #54c6ff solid !important;
  width: 87% !important;
  left: 2% !important;
  background-color: #4c4c4c !important;
  border-radius: 14px !important;
}

.introjs-helperLayer.step1 {
  width: 95% !important;
}

.step2-arrow-right {
  height: 100px !important;
  background-image: url(/images/BlueOverlayArrowTop.svg);
  background-repeat: no-repeat;
  width: 100px !important;
  position: absolute;
  right: 5px;
  top: -5px;
}

.step2-arrow-left {
  height: 100px !important;
  top: -5px;
  position: absolute;
  left: 5px;
  background-image: url(/images/BlueOverlayArrowTop.svg);
  background-repeat: no-repeat;
  width: 100px !important;
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.step3 {
  border: 2px #54c6ff solid !important;
  height: 44px !important;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  width: 48px !important;
  top: 35px !important;
}

.burger.introjs-showElement{
  visibility: visible !important;
  padding: 7px 0px 2px 0px;
  border-radius: 50%;
}

.darken-overlay {
  background: rgba(33, 33, 33, 0.8) !important;
}

.darken-overlay .arrows {
  border: 2px #54c6ff solid !important;
  padding: 5px;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
}

a {
  text-decoration: none;
}

#db-chat-widget-root button[class*='Header__HeaderButton'] {
  width: 20px;
}

@media (min-width: 375px) and (max-width: 767px) {
  .introjs-helperLayer.withBackgroundFix:after {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 1000;
    background: rgba(33, 33, 33, 0.8);
  }

  .introjs-helperLayer.withBackgroundFix {
    box-shadow: none !important;
  }
}

@media  (min-width: 770px) and (max-width: 1250px) {
  .tooltip1 {
    top: 17% !important;
  }
}

@media (max-width: 770px) {
  .tooltip1 {
    top: 15% !important;
  }
  .step3 {
    top: 20px !important;
  }
}

.onboarding-tf .intro-map{
  background-image: url('/images/map.svg')
}
.onboarding-st .intro-map{
  background-image: url('/images/mapST.svg')
}

.onboarding-tf .onboarding-title,
.onboarding-tf .onboarding-subtitle {
  color: #012979;
}

.onboarding-st .onboarding-title,
.onboarding-st .onboarding-subtitle {
  color: #000000 !important;
}
